export default {
  setRiskCollections (state, riskCollections) {
    state.riskCollections = Object.assign({}, state.riskCollections, riskCollections)
  },
  setTypeOptions (state, riskTypes) {
    state.riskTypes = Object.assign([], state.riskTypes, riskTypes)
  },
  setSourceOptions (state, riskSources) {
    state.riskSources = Object.assign({}, state.riskSources, riskSources)
  },
  setLoadedState (state, loaded) {
    state.loaded = Object.assign({}, state.loaded, loaded)
  },
  setRiskLayers (state, layers) {
    state.layers = Object.assign({}, state.layers, layers)
  }
}
