/**
 * Breaking changes in vue-socket.io-extended v4.x.x
 * @see https://github.com/probil/vue-socket.io-extended/releases/tag/v4.0.0
 */
import $socket from '@/socket-instance'
import jwtDecode from 'jwt-decode'

/**
 * socket.io actions follow a specific format,
 * which is not compliant with ESLint.
 * So we disable ESLint for some lines.
 *
 * Server Event | Mutation            | Action
 * chat message | SOCKET_CHAT MESSAGE | socket_chatMessage
 * chat_message | SOCKET_CHAT_MESSAGE | socket_chatMessage
 * chatMessage  | SOCKET_CHATMESSAGE  | socket_chatMessage
 * CHAT_MESSAGE | SOCKET_CHAT_MESSAGE | socket_chatMessage
 */

// eslint-disable-next-line camelcase
export function socket_fetchUserById (context, _id) {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem('token')
    if (!token) {
      reject(Error('No token provided.'))
      return
    }

    $socket.emit('getUser', { token, _id }, (response) => {
      if (!response.error) {
        resolve(response)
      } else reject(Error(response.error))
    })
  })
}

// eslint-disable-next-line camelcase
export function socket_fetchUsers ({ commit }) {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem('token')
    if (!token) {
      reject(Error('No token provided.'))
      return
    }

    $socket.emit('getUsers', { token }, (response) => {
      if (!response.error) {
        commit('SET_USERS_LIST', response)
        resolve()
      } else reject(Error(response.error))
    })
  })
}

// eslint-disable-next-line
export const socket_tryProfileCreate = ({commit, state, rootState}, payload) => new Promise((resolve, reject) => {
  const token = localStorage.getItem('token')
  if (!token) return reject(Error('No token provided.'))

  $socket.emit('createUser', { token, ...payload }, (response) => {
    if (response.error) reject(Error(response.message))
    else {
      // update local users list
      try {
        const users = JSON.parse(JSON.stringify(state.users))
        users.push(response.user)
        commit('SET_USERS_LIST', users)
      } catch (error) {
        commit('SET_USERS_LIST', null)
      }

      resolve(response.user._id)
    }
  })
})

// eslint-disable-next-line
export const socket_tryProfileUpdate = ({commit, state, rootState}, user) => new Promise((resolve, reject) => {
  const token = localStorage.getItem('token')
  if (!token) return reject(Error('No token provided.'))

  $socket.emit('updateUser', { token, user }, (response) => {
    if (response.error) reject(Error(response.message))
    else {
      // If we updated ourself we will update the local state
      if (user._id === rootState.account.user._id) {
        commit('account/setAuth', response.auth, { root: true })
        commit('account/setUser', response.user, { root: true })
        commit('account/setToken', response.token, { root: true })
        commit('account/setTokenExp', jwtDecode(response.token).exp, { root: true })
      } else {
        // update local users list and set user being edited
        commit('SET_EDITING_USER', response.user)

        // Find the position of the users Object before the update
        const indexBeforeUpdate = state.users.findIndex(u => u._id === response.user._id)
        if (indexBeforeUpdate > -1) {
          // Create a copy so we don't update the state directly
          const users = JSON.parse(JSON.stringify(state.users))
          // Replace the old Object with the new
          users.splice(indexBeforeUpdate, 1, response.user)
          // Update the users list
          commit('SET_USERS_LIST', users)
        } else {
          reject(Error('Could not update local users list'))
          return
        }
      }

      resolve()
    }
  })
})

// eslint-disable-next-line
export const socket_tryPasswordUpdate = (context, payload) => new Promise((resolve, reject) => {
  const token = localStorage.getItem('token')
  if (!token) return reject(Error('No token provided.'))

  $socket.emit('updatePassword', { token, ...payload }, (response) => {
    if (response.error) reject(Error(response.message))
    else resolve()
  })
})

// eslint-disable-next-line
export function socket_tryProfileDelete ({ dispatch }, { _id }) {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem('token')
    if (!token) return reject(Error('No token provided.'))

    $socket.emit('deleteUser', { token, _id }, (response) => {
      if (response.error) reject(Error(response.message))
      else {
        dispatch('socket_fetchUsers')
          .then(resolve)
          .catch(reject)
      }
    })
  })
}

// eslint-disable-next-line
export const socket_tryProfileActivate = ({commit, state}, { _id }) => new Promise((resolve, reject) => {
  const token = localStorage.getItem('token')
  if (!token) return reject(Error('No token provided.'))

  $socket.emit('activateUser', { token, _id }, (response) => {
    if (response.error) reject(Error(response.message))
    else {
      // update local users list and set user being edited
      commit('SET_EDITING_USER', response.user)

      // Find the position of the users Object before the update
      const indexBeforeUpdate = state.users.findIndex(u => u._id === response.user._id)
      if (indexBeforeUpdate > -1) {
        // Create a copy so we don't update the state directly
        const users = JSON.parse(JSON.stringify(state.users))

        // Replace the old Object with the new
        users.splice(indexBeforeUpdate, 1, response.user)
        // Update the users list
        commit('SET_USERS_LIST', users)
      } else {
        reject(Error('Could not update local users list'))
        return
      }

      resolve()
    }
  })
})
