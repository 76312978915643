/**
 * Breaking changes in vue-socket.io-extended v4.x.x
 * @see https://github.com/probil/vue-socket.io-extended/releases/tag/v4.0.0
 */
import $socket from '@/socket-instance'
import optimisation from '@/assets/datasamples/optimisation.json'

/**
 * socket.io actions follow a specific format,
 * which is not compliant with ESLint.
 * So we disable ESLint for some lines.
 *
 * Server Event | Mutation            | Action
 * chat message | SOCKET_CHAT MESSAGE | socket_chatMessage
 * chat_message | SOCKET_CHAT_MESSAGE | socket_chatMessage
 * chatMessage  | SOCKET_CHATMESSAGE  | socket_chatMessage
 * CHAT_MESSAGE | SOCKET_CHAT_MESSAGE | socket_chatMessage
 */

// ----------------------------------------------------- Global -----------------------------------------------------
// eslint-disable-next-line
export function socket_fetchSurveyData ({ commit, rootState }) {
  return new Promise((resolve, reject) => {
    commit('setPendingState', true)

    $socket.emit('getMostRecentSurveys', { token: rootState.account.token }, (surveys) => {
      commit('setSurveyData', surveys)
      commit('setPendingState', false)
      resolve(surveys)
    })
  })
}

// eslint-disable-next-line
export function socket_updateSurveyData ({ commit, rootState, state }, password) {
  return new Promise((resolve, reject) => {
    commit('setPendingState', true)

    $socket.emit('updateSurvey', { token: rootState.account.token, update: state.surveyData, password }, (response) => {
      commit('setPendingState', false)
      if (!response.error) resolve()
      else reject(response)
    })
  })
}

// eslint-disable-next-line
export function socket_fetchTableFooter ({ commit, rootState }) {
  return new Promise((resolve, reject) => {
    commit('setPendingState', true)

    $socket.emit('getLatestInspTableFooter', { token: rootState.account.token }, (response) => {
      commit('setPendingState', false)

      if (!response.error) {
        commit('setTableFooter', response.content)
        // commit('setTableFooter', '* If not yet cleaned.\n** Based on 2018 results this can be shifted to 2021.\n*** Depending on results of 2020.\nCP Survey will be performed in 2020 and 2025.')
        resolve()
      } else reject(Error(response.error))
    })
  })
}

// eslint-disable-next-line
export function socket_updateTableFooter ({ commit, rootState }, content) {
  return new Promise((resolve, reject) => {
    commit('setPendingState', true)

    $socket.emit('updateInspTableFooter', { token: rootState.account.token, content }, (response) => {
      commit('setPendingState', false)
      commit('setTableFooter', response.content)

      if (!response.error) resolve()
      else reject(Error(response.error))
    })
  })
}

// ----------------------------------------------------- Revisions add-on -----------------------------------------------------
// eslint-disable-next-line
export function socket_fetchRevisions ({ commit, rootState }) {
  return new Promise((resolve, reject) => {
    commit('setRevisionsFetchedState', false)
    commit('setPendingState', true)
    commit('setRevisionsPendingState', true)

    $socket.emit('getSurveyRevisions', { token: rootState.account.token }, (revisions) => {
      commit('setRevisions', revisions)
      commit('setRevisionsFetchedState', true)
      commit('setRevisionsPendingState', false)
      commit('setPendingState', false)
      resolve()
    })
  })
}

// eslint-disable-next-line
export function socket_fetchRevisionData ({ commit, rootState }, timestamp) {
  return new Promise((resolve, reject) => {
    commit('setPendingState', true)

    $socket.emit('getSurveys', { token: rootState.account.token, params: { lastUpdate: timestamp } }, (data) => {
      commit('setPendingState', false)
      commit('setRevisionData', data)
      resolve(data)
    })
  })
}

// ----------------------------------------------------- IRM Optimisation add-on -----------------------------------------------------
// eslint-disable-next-line
export function socket_fetchIrmOptimisation ({ commit, rootState }) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(optimisation)
    }, 1000)
  })
}
