export default {
  // Is this app active?
  enabled: true,

  // App namespace
  ns: 'dashboard',

  // App makes use of routing
  usesRouting: true,

  // The name of this app
  name: 'Dashboard',

  // Alternative label
  label: 'Dashboard',

  // Nav item secondary text
  subtext: 'KPI overview',

  // Priority of order to show in nav drawer, lower means shown higher in the list
  priority: 4,

  // Icon to use to identify this app
  icon: 'pie_chart'
}
