/**
 * Breaking changes in vue-socket.io-extended v4.x.x
 * @see https://github.com/probil/vue-socket.io-extended/releases/tag/v4.0.0
 */
import $socket from '@/socket-instance'

/**
 * socket.io actions follow a specific format,
 * which is not compliant with ESLint.
 * So we disable ESLint for some lines.
 *
 * Server Event | Mutation            | Action
 * chat message | SOCKET_CHAT MESSAGE | socket_chatMessage
 * chat_message | SOCKET_CHAT_MESSAGE | socket_chatMessage
 * chatMessage  | SOCKET_CHATMESSAGE  | socket_chatMessage
 * CHAT_MESSAGE | SOCKET_CHAT_MESSAGE | socket_chatMessage
 */

/**
  * Fetch Charts
  * @param {*} store
  */
// eslint-disable-next-line
export function socket_getChartData ({ commit, rootState }, chartTypes) {
  if (typeof chartTypes === 'string') chartTypes = [chartTypes]

  return new Promise((resolve, reject) => {
    $socket.emit('getChartData', { token: rootState.account.token, chartTypes }, (chartData) => {
      commit('setChartData', chartData)
      resolve()
    })
  })
}
