export default {
  // ----------------------------------------------------- Global -----------------------------------------------------
  map: null, // mapbox instance
  mapboxToken: 'pk.eyJ1IjoiaXJtLXNwZC1kZWxmdCIsImEiOiJjanc3ZjN0bWEwNDBrM3pxdW00dzRvZWQ1In0.4PLcB_959LC7OLptvO2Gzw',
  mapStyles: [
    { title: 'Light', uri: 'mapbox://styles/mapbox/light-v10?optimize=true' },
    { title: 'Dark', uri: 'mapbox://styles/mapbox/dark-v10?optimize=true' },
    { title: 'Streets', uri: 'mapbox://styles/mapbox/streets-v11?optimize=true' },
    { title: 'Terrain', uri: 'mapbox://styles/mapbox/outdoors-v11?optimize=true' },
    { title: 'Satellite', uri: 'mapbox://styles/mapbox/satellite-streets-v11?optimize=true' }
  ],
  mapStyle: 'mapbox://styles/mapbox/light-v10?optimize=true',
  mapScaleControl: { show: true, position: 'bottom-left' },
  mapFullScreenControl: { show: true, position: 'top-left' },
  mapNavControl: { show: true, position: 'top-left' },
  mapLoaded: false,
  mapIdle: true,
  mouseCoords: { lng: 0, lat: 0 },
  mapFilter: [],
  unifiedTurfFilters: [],
  dataPending: false,
  layersPending: false,
  sourceObjs: [],
  layerObjs: [],
  activeLayers: [],
  isMeasuring: false,
  spiderifier: null,
  spiderActive: false,
  // ----------------------------------------------------- Asset Control add-on -----------------------------------------------------
  selectedAssets: [],
  selectedAssetLayers: [],
  selectedAssetProps: [],
  selectedAssetEprs: [],
  // ----------------------------------------------------- Layer Control add-on -----------------------------------------------------
  selectedLayerOptions: [],
  selectedLayerTppOptions: [],
  selectedLayerCableOptions: [],
  // ----------------------------------------------------- Property Control add-on -----------------------------------------------------
  selectedProps: [],
  // ----------------------------------------------------- Inspection Control add-on -----------------------------------------------------
  checkedInspTypes: [],
  checkedInspSelections: [],
  checkedInspSelectionsHidden: [],
  checkedInspFeatureOptions: [],
  linesShown: false,
  checkedInspOptionsMap: {},
  // ----------------------------------------------------- Event Line Control add-on -----------------------------------------------------
  selectedEventLines: [],
  // ----------------------------------------------------- Filter Control add-on -----------------------------------------------------
  filterRefs: {},
  // ----------------------------------------------------- Risk Toggle/Control add-on -----------------------------------------------------
  selectedRisks: [],
  selectedSepRisks: [],
  selectedRiskMap: {},
  // ----------------------------------------------------- Risks Pof/Cof Control add-on -----------------------------------------------------
  selectedRiskType: null,
  selectedRiskSource: null,
  // ----------------------------------------------------- Bottom Chart add-on -----------------------------------------------------
  activeMouseLine: null,
  chartHeight: '30vh',
  chartActive: false,
  mouseKp: 0
}
