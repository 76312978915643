/**
 * Breaking changes in vue-socket.io-extended v4.x.x
 * @see https://github.com/probil/vue-socket.io-extended/releases/tag/v4.0.0
 */
import $socket from '@/socket-instance'

/**
 * socket.io actions follow a specific format,
 * which is not compliant with ESLint.
 * So we disable ESLint for some lines.
 *
 * Server Event | Mutation            | Action
 * chat message | SOCKET_CHAT MESSAGE | socket_chatMessage
 * chat_message | SOCKET_CHAT_MESSAGE | socket_chatMessage
 * chatMessage  | SOCKET_CHATMESSAGE  | socket_chatMessage
 * CHAT_MESSAGE | SOCKET_CHAT_MESSAGE | socket_chatMessage
 */

/**
  * Fetch client specific risk data for use in the 'RiskTogglePofCof' component
  * @param {*} store
  */
// eslint-disable-next-line
export function socket_fetchRiskData ({ commit, rootState }) {
  return new Promise((resolve, reject) => {
    let riskLabels
    let riskTypes
    const riskTypeLabelDict = {}
    const riskCollections = {}

    // Fetch labels
    const labelsPromise = new Promise((resolve, reject) => {
      $socket.emit('getDistinctFeatures', {
        token: rootState.account.token,
        distinct: 'properties.label',
        params: {
          'properties.risk_type': { $exists: true }
        }
      }, (response) => {
        if (!response.error) {
          riskLabels = response
          resolve()
        } else {
          reject(Error(response.message))
        }
      })
    })

    // Fetch types
    const typesPromise = new Promise((resolve, reject) => {
      $socket.emit('getDistinctFeatures', {
        token: rootState.account.token,
        distinct: 'properties.risk_type'
      }, (response) => {
        if (!response.error) {
          riskTypes = response
          resolve()
        } else {
          reject(Error(response.message))
        }
      })
    })

    // Fetch feature collections
    const featuresPromise = new Promise((resolve, reject) => {
      $socket.emit('getFeatures', {
        token: rootState.account.token,
        params: {
          'properties.risk_type': { $exists: true }
        }
      }, (response) => {
        if (!response.error) {
          // Map Feature Collections by risk type
          response.forEach((doc) => {
            riskCollections[doc.properties.risk_type] = doc.data
          })
          resolve()
        } else {
          reject(Error(response.message))
        }
      })
    })

    Promise.all([labelsPromise, typesPromise, featuresPromise])
      .then(() => {
        /**
         * Create a dictionary for the labels.
         * This should be done by the server with a seperate socket call (aggregate).
         * For now it is 'safe' to assume that the labels and types are in the same order,
         * as we did not sort the result in any way and the queries are basically the same.
         * Then, instead of using 'riskTypes', we can use Object.keys(riskTypeLabelDict)
         */
        riskTypes.forEach((type, i) => {
          riskTypeLabelDict[type] = riskLabels[i]
        })

        // Top-level risk type options, first option added here
        const riskTypeOptions = [{
          text: 'None',
          value: null
        }]

        // Risk sources for the matching top-level risk type
        const riskTypeSources = {}

        // Loop through all risk types to fill the type/source options
        for (const type of riskTypes) {
          // Add top-level risk type option
          riskTypeOptions.push({
            text: riskTypeLabelDict[type] || type,
            value: type
          })

          // Add risk type source option
          riskTypeSources[type] = []
          for (const prop of Object.keys(riskCollections[type].features[0].properties)) {
            if (['Start', 'End', 'pipeline_indicator'].includes(prop)) continue

            riskTypeSources[type].push({
              text: prop,
              value: prop
            })
          }

          // Sort the order of the source options here
          riskTypeSources[type] = riskTypeSources[type].sort((a, b) => {
            if (a.value === 'Total') return -10
            else if (a.value < b.value) return -10
            return 10
          })
        }

        // Commit data
        commit('setRiskCollections', riskCollections)
        commit('setTypeOptions', riskTypeOptions)
        commit('setSourceOptions', riskTypeSources)
        commit('setLoadedState', { riskOptions: true })
        resolve()
      })
      .catch((error) => reject(error))
  })
}
