export default {
  riskTypes: [],
  riskSources: {},
  riskCollections: {},
  loaded: {
    riskOptions: false
  },
  layers: {},
  sources: {}
}
