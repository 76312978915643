import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@fortawesome/fontawesome-free/css/all.css'
import FaFilter from '@/components/icons/FaFilter.vue'
import FaSlidersH from '@/components/icons/FaSlidersH.vue'
import IconAsset from '@/components/icons/Asset.vue'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#00AEEF',
        secondary: '#8f68c4',
        accent: '#82B1FF',
        error: '#db2828',
        info: '#31ccec',
        success: '#21ba45',
        warning: '#f2c037',
        white: '#FFFFFF',
        spd: '#00AEEF'
      }
    }
  },
  icons: {
    iconfont: 'md',
    values: {
      faFilter: {
        component: FaFilter
      },
      faSlidersH: {
        component: FaSlidersH
      },
      iconAsset: {
        component: IconAsset
      }
    }
  }
})
