export default {
  // Is this app active?
  enabled: true,

  // App namespace
  ns: 'mapbox',

  // App makes use of routing
  usesRouting: false,

  // The name of this app
  name: 'Mapbox',

  // Priority of order to show in nav drawer, lower means shown higher in the list
  priority: 1,

  // Alternative label
  label: 'Mapviewer',

  // Nav item secondary text
  subtext: 'GIS viewer',

  // Icon to use to identify this app
  icon: 'map',

  // Enabled app features
  features: {
    assetControl: true, // Asset control
    layerControl: true, // Layer control
    propControl: false, // Property control - deprecated
    inspectionControl: true, // Inspection control
    inspCtrlCompare: false, // Inspection control comparison tool - deprecated
    eventLineControl: false, // Event Line control - deprecated
    filterControl: true, // Filter control
    // Risk mode, only 1 of them can be set to true
    riskToggleBasic: true,
    riskToggleMenu: false,
    riskTogglePofCof: false, // Not yet fully developed!
    incidentToggle: true,
    bottomChart: true
  }
}
