/**
 * Breaking changes in vue-socket.io-extended v4.x.x
 * @see https://github.com/probil/vue-socket.io-extended/releases/tag/v4.0.0
 */
import $socket from '@/socket-instance'

/**
 * socket.io actions follow a specific format,
 * which is not compliant with ESLint.
 * So we disable ESLint for some lines.
 *
 * Server Event | Mutation            | Action
 * chat message | SOCKET_CHAT MESSAGE | socket_chatMessage
 * chat_message | SOCKET_CHAT_MESSAGE | socket_chatMessage
 * chatMessage  | SOCKET_CHATMESSAGE  | socket_chatMessage
 * CHAT_MESSAGE | SOCKET_CHAT_MESSAGE | socket_chatMessage
 */

// eslint-disable-next-line
export function socket_fetchFiles ({ commit, rootState }, params) {
  return new Promise((resolve, reject) => {
    $socket.emit('getFiles', { token: rootState.account.token, params }, (res) => {
      if (res.error) reject(Error(res.error))
      else resolve(res)
    })
  })
}

// eslint-disable-next-line
export function socket_fetchFileById ({ commit, rootState }, id) {
  return new Promise((resolve, reject) => {
    $socket.emit('getFileById', { token: rootState.account.token, params: { id } }, (res) => {
      if (res.error) reject(Error(res.error))
      else resolve(res)
    })
  })
}
