import Vue from 'vue'
import Vuex from 'vuex'
import _ from 'lodash'
import moment from 'moment'
import app from './module-app'
import account from './module-account'
import socket from './module-socket'
import mapbox from './module-mapbox'
import assets from './module-assets'
import legends from './module-legends'
import matrix from './module-risk-matrix'
import risks from './module-risks'
import surveys from './module-surveys'
import fileUploader from './module-file-uploader'
import charts from './module-charts'
import eprs from './module-eprs'
import settings from './module-settings'
import { version } from '../../package.json'

const cacheEnabled = process.env.VUE_APP_CACHING === 'true'
const cacheExpiresMs = parseInt(process.env.VUE_APP_CACHE_EXPIRATION_MS)
let cacheFull = false

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app,
    account,
    socket,
    mapbox,
    assets,
    legends,
    matrix,
    risks,
    surveys,
    fileUploader,
    charts,
    eprs,
    settings
  },
  state: {
    // Cache version
    version: '',
    expires: ''
  },
  mutations: {
    setStoreValidation (state) {
      state.version = version
      state.expires = moment().add(cacheExpiresMs)
    },
    initialiseStore (state) {
      // Store the initial state Object as a JSON string
      if (!localStorage.getItem('init')) {
        try {
          localStorage.setItem('init', JSON.stringify(store.state))
        } catch (error) {
          console.warn('Cache storage full. Disabling caching...')
          cacheFull = true
        }
      }

      if (!cacheEnabled) return

      // Check if the cache exists
      if (localStorage.getItem('store')) {
        const store = JSON.parse(localStorage.getItem('store'))

        // Check the version stored against current. If different, don't
        // load the cached version
        if (store.version === version && moment().isBefore(store.expires)) {
          this.replaceState(_.merge(state, store))
        } else {
          localStorage.removeItem('store')
          this.commit('setStoreValidation')
        }
      } else {
        this.commit('setStoreValidation')
      }
    },
    resetStore (state) {
      const store = JSON.parse(localStorage.getItem('init'))
      if (store) this.replaceState(_.merge(state, store))
    }
  },
  actions: {
    logout ({ commit }) {
      commit('account/logout')
      commit('resetStore')
    }
  }
})

if (cacheEnabled) {
  /**
   * Only mutations defined in this Array will trigger a cache update
   */
  const mutationsToCache = [
    // Module - app
    'app/setDrawerItems',
    // Module - assets
    'assets/setAssets',
    'assets/setAssetOptions',
    'assets/setAttachments',
    // Module - legends
    'legends/setLegends',
    // Module - mapbox
    'mapbox/setMapStyle',
    'mapbox/setLayerIndex',
    'mapbox/setSourceObjs',
    'mapbox/updateSourceObj',
    'mapbox/setLayerObjs',
    'mapbox/updateSourceObj',
    'mapbox/setFilterRefs',
    'mapbox/updateFilterRefs',
    // Module - matrix
    'matrix/setRows',
    // Module - risks
    'risks/setRiskCollections',
    'risks/setTypeOptions',
    'risks/setSourceOptions',
    // Module - surveys
    'surveys/setSurveyData'
  ]

  /**
   * Subscribe to the store so we can execute our logics whenever the state changes.
   */
  store.subscribe(({ type }, state) => {
    if (!mutationsToCache.includes(type) || cacheFull) return
    let stateClone = {}

    // Setup clones of the states which we want to include in the cache
    const expirationState = { version: state.version, expires: state.expires }
    const appState = { app: state.app }
    const mapboxState = { mapbox: {} }
    const assetsState = { assets: state.assets }
    // let matrixState = { matrix: state.matrix } Risk matrixes will not get cached
    // let surveysState = { surveys: {} } // Surveys will not get cached

    /**
   * Filter state properties which we don't want to cache.
  */
    /**
   * Mapbox caching is only used for the data assigned to the source Objects.
   * Map states (active layers, filters, selections, etc.) will still get overwritten within the code to their initial (calculated) values.
   * This is done on purpose, because we assume that the user wants a fresh page if it gets refreshed.
   * However, we still decide to set the map base style to it's last known value.
   */
    const mapboxExcludeKeys = [
      'map',
      'mapLoaded',
      'mapIdle',
      'dataPending',
      'layersPending',
      'activeLayers',
      'isMeasuring',
      'spiderifier',
      'selectedAssets',
      'selectedLayerOptions',
      'selectedProps',
      'selectedEventsShown',
      'selectedEventsHidden',
      'selectedEventLines',
      'filterRefs',
      'selectedRisks',
      'selectedRiskType',
      'selectedRiskSource',
      'activeLegends'
    ]
    for (const key in state.mapbox) {
      if (!mapboxExcludeKeys.includes(key)) mapboxState.mapbox[key] = state.mapbox[key]
    }

    // Merge all states into a complete/filtered clone of state
    stateClone = _.merge(stateClone, expirationState, appState, mapboxState, assetsState)

    // Store the state Object as a JSON string
    try {
      localStorage.setItem('store', JSON.stringify(stateClone))
    } catch (error) {
      console.warn('Cache storage full. Disabling caching...')
      cacheFull = true
    }
  })
}
Vue.prototype.$store = store
export default store
