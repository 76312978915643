/**
 * Breaking changes in vue-socket.io-extended v4.x.x
 * @see https://github.com/probil/vue-socket.io-extended/releases/tag/v4.0.0
 */
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Vue from 'vue'
import App from './App.vue'
import router from './router/'
import store from './store/'
import './registerServiceWorker'
import vuetify from './plugins/vuetify'
import $ac from '@/plugins/accesscontrol'
import Vuelidate from 'vuelidate'
import VueSocketio from 'vue-socket.io-extended'
import $socket from './socket-instance'
import VueBreadcrumbs from 'vue-breadcrumbs'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.config.productionTip = false

Vue.use(VueSocketio, $socket, { store })
Vue.use(require('vue-chartist'))
Vue.use($ac)
Vue.use(Vuelidate)
Vue.use(VueBreadcrumbs, { registerComponent: false })

const vm = new Vue({
  router: router(),
  store,
  vuetify,
  render: h => h(App),
  beforeCreate () {
    this.$store.commit('initialiseStore')
  }
}).$mount('#app')

// Init account state from localStorage
store.commit('account/initState')

// when everything is ready for a connection (correct page, auth info in store, etc)
$socket.open()

// Listen for connecting events
$socket.on('connect', () => {
  store.commit('socket/setSocketConnStatus', $socket.connected)
})

// Listen for disconnecting events
const disconnEvents = [
  'error',
  'disconnect',
  'reconnect',
  'reconnect_attempt',
  'reconnecting',
  'reconnect_error',
  'reconnect_failed',
  'connect_error',
  'connect_timeout',
  'connecting'
]
for (const event of disconnEvents) {
  $socket.on(event, (payload) => {
    console.log(event, payload)
    store._mutations['socket/setSocketConnStatus'][0]($socket.connected)

    if (payload === '401' && vm.$router.currentRoute.path !== '/login') {
      vm.$router.push({
        path: '/login',
        query: {
          session_expired: 1
        }
      })
    }
  })
}
