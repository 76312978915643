export default {
  // Is this app active?
  enabled: true,

  // App namespace
  ns: 'eprs',

  // App makes use of routing
  usesRouting: true,

  // The name of this app
  name: 'EPRS',

  // Alternative label
  label: 'EPRS',

  // Nav item secondary text
  subtext: 'Assessments and reports',

  // Priority of order to show in nav drawer, lower means shown higher in the list
  priority: 3,

  // Icon to use to identify this app
  icon: 'local_fire_department'
}
