// ----------------------------------------------------- Global -----------------------------------------------------
/**
 * Gets the general mapbox options
 */
export function mapOptions (state) {
  return {
    style: state.mapStyle,
    center: [0, 0],
    zoom: 1.2,
    attributionControl: false
  }
}

/**
 * Gets a safe-to-use clone of 'sourceObjs'
 */
export function sourceObjs (state) {
  return JSON.parse(JSON.stringify(state.sourceObjs))
}

/**
 * Gets a safe-to-use clone of 'layerObjs'
 */
export function layerObjs (state) {
  return JSON.parse(JSON.stringify(state.layerObjs))
}

export function layerIds (state) {
  return state.layerObjs.map(l => l.id)
}

/**
 * Gets a safe-to-use clone of 'activeLayers'
 */
export function activeLayers (state) {
  return [...state.activeLayers]
}

export function sourceIds (state) {
  return state.sourceObjs.map(src => src.properties.id)
}

export function activeLayerObjs (state) {
  const matching = state.layerObjs.filter(l => state.activeLayers.includes(l.id))
  return [...matching]
}

// ----------------------------------------------------- Asset Control add-on -----------------------------------------------------
/**
 * Gets options for use in the mapbox assets control add-on
 */
export function assetOptions (state) {
  const categories = ['asset']
  const matching = state.layerObjs.filter(l => categories.includes(l.properties.category) && !l.properties.categoryType && !l.properties.categoryType)

  const options = matching.map(l => {
    return { text: l.properties.label || l.id, value: l.id }
  })

  // Sort options alphabetically
  const sorted = options.sort((a, b) => {
    if (a.text < b.text) return -1
    if (a.text > b.text) return 1
    return 0
  })

  return sorted
}

export function initialAssets (state) {
  const categories = ['asset']
  const matching = state.layerObjs.filter(l => categories.includes(l.properties.category) && !l.properties.categoryType && l.properties.isInitial)
  return matching.map(l => l.id).sort()
}

export function assetLayerOptions (state) {
  const categories = ['asset']
  const types = ['layer']
  const matching = state.layerObjs.filter(l => categories.includes(l.properties.category) && types.includes(l.properties.categoryType))

  const options = matching.map(l => {
    return { text: l.properties.label || l.id, value: l.id }
  })

  // Sort options alphabetically
  const sorted = options.sort((a, b) => {
    if (a.text < b.text) return -1
    if (a.text > b.text) return 1
    return 0
  })

  return sorted
}

export function initialAssetLayers (state) {
  const categories = ['asset']
  const types = ['layer']
  const matching = state.layerObjs.filter(l => categories.includes(l.properties.category) && types.includes(l.properties.categoryType) && l.properties.isInitial)
  return matching.map(l => l.id).sort()
}

export function assetPropOptions (state) {
  const categories = ['asset']
  const types = ['property']
  const matching = state.layerObjs.filter(l => categories.includes(l.properties.category) && types.includes(l.properties.categoryType))

  const options = matching.map(l => {
    return { text: l.properties.label || l.id, value: l.id }
  })

  // Sort options alphabetically
  const sorted = options.sort((a, b) => {
    if (a.text < b.text) return -1
    if (a.text > b.text) return 1
    return 0
  })

  return sorted
}

export function initialAssetProps (state) {
  const categories = ['asset']
  const types = ['property']
  const matching = state.layerObjs.filter(l => categories.includes(l.properties.category) && types.includes(l.properties.categoryType) && l.properties.isInitial)
  return matching.map(l => l.id).sort()
}

export function assetEprsOptions (state) {
  const categories = ['asset']
  const types = ['eprs']
  const matching = state.layerObjs.filter(l => categories.includes(l.properties.category) && types.includes(l.properties.categoryType))

  const options = matching.map(l => {
    return { text: l.properties.label || l.id, value: l.id }
  })

  // Sort options alphabetically
  const sorted = options.sort((a, b) => {
    if (a.text < b.text) return -1
    if (a.text > b.text) return 1
    return 0
  })

  return sorted
}

export function initialAssetEprs (state) {
  const categories = ['asset']
  const types = ['eprs']
  const matching = state.layerObjs.filter(l => categories.includes(l.properties.category) && types.includes(l.properties.categoryType) && l.properties.isInitial)
  return matching.map(l => l.id).sort()
}

// ----------------------------------------------------- Layer Control add-on -----------------------------------------------------
/**
 * Gets options for use in the mapbox layer control add-on
 */
export function layerOptions (state) {
  const categories = ['layer']
  const matching = state.layerObjs.filter(l => categories.includes(l.properties.category) && !l.properties.categoryType)

  const options = matching.map(l => {
    return { text: l.properties.label || l.id, value: l.id }
  })

  // Sort options alphabetically
  const sorted = options.sort((a, b) => {
    if (a.text < b.text) return -1
    if (a.text > b.text) return 1
    return 0
  })

  return sorted
}

/**
 * Gets initial options for use in the mapbox layer control add-on
 */
export function initialLayers (state) {
  const categories = ['layer']
  const matching = state.layerObjs.filter(l => categories.includes(l.properties.category) && !l.properties.categoryType && l.properties.isInitial)
  return matching.map(l => l.id)
}

export function layerTppOptions (state) {
  const categories = ['layer']
  const types = ['tpp']
  const matching = state.layerObjs.filter(l => categories.includes(l.properties.category) && types.includes(l.properties.categoryType))

  const options = matching.map(l => {
    return { text: l.properties.label || l.id, value: l.id }
  })

  // Sort options alphabetically
  const sorted = options.sort((a, b) => {
    if (a.text < b.text) return -1
    if (a.text > b.text) return 1
    return 0
  })

  return sorted
}

export function initialLayerTpps (state) {
  const categories = ['layer']
  const types = ['tpp']
  const matching = state.layerObjs.filter(l => categories.includes(l.properties.category) && types.includes(l.properties.categoryType) && l.properties.isInitial)
  return matching.map(l => l.id)
}

export function layerCableOptions (state) {
  const categories = ['layer']
  const types = ['cable']
  const matching = state.layerObjs.filter(l => categories.includes(l.properties.category) && types.includes(l.properties.categoryType))

  const options = matching.map(l => {
    return { text: l.properties.label || l.id, value: l.id }
  })

  // Sort options alphabetically
  const sorted = options.sort((a, b) => {
    if (a.text < b.text) return -1
    if (a.text > b.text) return 1
    return 0
  })

  return sorted
}

export function initialLayerCables (state) {
  const categories = ['layer']
  const types = ['cable']
  const matching = state.layerObjs.filter(l => categories.includes(l.properties.category) && types.includes(l.properties.categoryType) && l.properties.isInitial)
  return matching.map(l => l.id)
}

// ----------------------------------------------------- Property Control add-on -----------------------------------------------------
/**
 * Gets options for use in the mapbox property control add-on
 */
export function propOptions (state) {
  const categories = ['property']
  const matching = state.layerObjs.filter(l => categories.includes(l.properties.category))

  const options = matching.map(l => {
    return { text: l.properties.label || l.id, value: l.id }
  })

  // Sort options alphabetically
  const sorted = options.sort((a, b) => {
    if (a.text < b.text) return -1
    if (a.text > b.text) return 1
    return 0
  })

  return sorted
}

/**
 * Gets initial options for use in the mapbox property control add-on
 */
export function initialProps (state) {
  const categories = ['property']
  const matching = state.layerObjs.filter(l => categories.includes(l.properties.category) && l.properties.isInitial)
  return matching.map(l => l.id)
}

// ----------------------------------------------------- Inspection Control add-on -----------------------------------------------------
/**
 * Gets options for use in the mapbox inspection control add-on
 */
export function inspTypeOptions (state) {
  const categories = ['inspection']
  const matching = state.layerObjs.filter(l => categories.includes(l.properties.category) && l.properties.categoryType && l.properties.selection && l.properties.categoryType !== 'Line')
  const types = [...new Set(matching.map(l => l.properties.categoryType))]

  const options = types.map(type => {
    return { text: type, value: type }
  })

  // Sort options alphabetically
  const sorted = options.sort((a, b) => {
    if (a.text < b.text) return -1
    if (a.text > b.text) return 1
    return 0
  })

  return sorted
}

export function inspOptionsMap (state) {
  const categories = ['inspection']
  const matching = state.layerObjs.filter(l => categories.includes(l.properties.category) && l.properties.categoryType && l.properties.selection && l.properties.categoryType !== 'Line')
  const map = {}

  // Map category types as Object property keys
  matching.forEach(l => {
    map[l.properties.categoryType] = []
  })

  // Map unique selections to the mapped category types
  for (const type in map) {
    const objs = matching.filter(l => l.properties.categoryType === type)

    const selections = objs.map(l => {
      return {
        text: l.properties.selection,
        value: l.id
      }
    })

    map[type] = selections
  }

  // sort numerically
  for (const key of Object.keys(map)) {
    map[key].sort((a, b) => (parseInt(a.text, 10) > parseInt(b.text, 10)) ? -1 : ((parseInt(a.text, 10) < parseInt(b.text, 10)) ? 1 : 0))
  }

  const ordered = {}
  Object.keys(map).sort().forEach((key) => {
    ordered[key] = map[key]
  })

  return ordered
}

// Togglable options
export function canToggleLines (state) {
  const categories = ['inspection']
  const matching = state.layerObjs.filter(l => {
    return (
      categories.includes(l.properties.category) &&
      l.properties.categoryType &&
      l.properties.selection &&
      l.properties.categoryType === 'Line'
    )
  })
  return matching.length > 0
}

export function lineOptions (state) {
  const categories = ['inspection']
  const matching = state.layerObjs.filter(l => categories.includes(l.properties.category) && l.properties.categoryType && l.properties.selection && l.properties.categoryType === 'Line')

  return matching.map(l => {
    return { text: l.properties.label, value: l.id }
  })
}

export function checkedInspLines (state) {
  if (!state.linesShown) return []
  const categories = ['inspection']

  // const matching = state.layerObjs.filter(l => {
  //   return (
  //     categories.includes(l.properties.category) &&
  //     l.properties.categoryType &&
  //     l.properties.categoryType === 'Line' &&
  //     l.properties.selection &&
  //     state.checkedInspSelections.includes(l.properties.selection)
  //   )
  // })

  const matching = state.layerObjs.filter(l => {
    return (
      categories.includes(l.properties.category) &&
      l.properties.categoryType &&
      l.properties.categoryType === 'Line' &&
      l.properties.selection &&
      state.checkedInspOptionsMap['Bathy Survey'] &&
      state.checkedInspOptionsMap['Bathy Survey'].find(o => o.includes(l.properties.selection))
    )
  })

  return matching.map(l => l.id)
}

// Separated options
export function inspSelectionOptions (state) {
  const categories = ['inspection']
  const matching = state.layerObjs.filter(l => categories.includes(l.properties.category) && l.properties.categoryType && l.properties.selection)
  const selections = [...new Set(matching.map(l => l.properties.selection))]

  const options = selections.map(selection => {
    return { text: selection, value: selection }
  })

  // Sort options alphabetically
  const sorted = options.sort((a, b) => {
    if (a.text < b.text) return -1
    if (a.text > b.text) return 1
    return 0
  })

  return sorted
}

export function inspFeatureOptions (state) {
  const categories = ['inspection']
  const matching = state.layerObjs.filter(l => categories.includes(l.properties.category) && l.properties.categoryType && !l.properties.selection)

  const options = matching.map(l => {
    return { text: l.properties.label, value: l.id }
  })

  // Sort options alphabetically
  const sorted = options.sort((a, b) => {
    if (a.text < b.text) return -1
    if (a.text > b.text) return 1
    return 0
  })

  return sorted
}

export function inspectionOptions (state) {
  const categories = ['inspection']
  const matching = state.layerObjs.filter(l => categories.includes(l.properties.category))

  const options = matching.map(l => {
    return { text: l.properties.label || l.id, value: l.id }
  })

  // Sort options alphabetically
  const sorted = options.sort((a, b) => {
    if (a.text < b.text) return -1
    if (a.text > b.text) return 1
    return 0
  })

  return sorted
}

export function selectedInspOptions (state) {
  const categories = ['inspection']
  const matching = state.layerObjs.filter(l => {
    /**
     * Filter layer Objects which:
     * - are within the specified categories
     * - are selected by their 'type' property and are selected by their 'selection' property
     */
    return categories.includes(l.properties.category) &&
      (state.checkedInspTypes.includes(l.properties.categoryType) &&
        state.checkedInspSelections.includes(l.properties.selection))
  })

  const options = matching.map(l => l.id)

  // Sort options alphabetically
  const sorted = options.sort((a, b) => {
    if (a.text < b.text) return -1
    if (a.text > b.text) return 1
    return 0
  })

  return sorted
}

// Initial options
export function initialInspTypeOptions (state) {
  const categories = ['inspection']

  const matching = state.layerObjs.filter(l => {
    return categories.includes(l.properties.category) &&
      l.properties.categoryType &&
      l.properties.categoryType !== 'Line' &&
      l.properties.selection &&
      l.properties.isInitial
  })

  return matching.map(l => l.properties.categoryType)
}

export function initialInspSelectionOptions (state) {
  const categories = ['inspection']

  const matching = state.layerObjs.filter(l => {
    return categories.includes(l.properties.category) &&
      l.properties.categoryType &&
      l.properties.selection &&
      l.properties.isInitial
  })

  return matching.map(l => l.properties.selection)
}

export function initialInspFeatureOptions (state) {
  const categories = ['inspection']

  const matching = state.layerObjs.filter(l => {
    return categories.includes(l.properties.category) &&
      l.properties.categoryType &&
      !l.properties.selection &&
      l.properties.isInitial
  })

  return matching.map(l => l.id)
}

export function initialInspLineOptions (state) {
  const categories = ['inspection']

  const matching = state.layerObjs.filter(l => {
    return (
      categories.includes(l.properties.category) &&
      l.properties.categoryType &&
      l.properties.categoryType === 'Line' &&
      l.properties.selection &&
      l.properties.isInitial
    )
  })

  return matching.map(l => l.id)
}

export function initialInspOptionsMap (state) {
  const categories = ['inspection']
  const matching = state.layerObjs.filter(l => {
    return (
      categories.includes(l.properties.category) &&
      l.properties.categoryType &&
      l.properties.selection &&
      l.properties.categoryType !== 'Line' &&
      l.properties.isInitial
    )
  })
  const map = {}

  // Map category types as Object property keys
  matching.forEach(l => {
    map[l.properties.categoryType] = []
  })

  // Map unique selections to the mapped category types
  for (const type in map) {
    const objs = matching.filter(l => l.properties.categoryType === type)

    const selections = objs.map(l => {
      return {
        text: l.properties.selection,
        value: l.id
      }
    })

    // let selections = [...new Set(objs.map(l => l.properties.selection))]
    // selections = selections.map(s => {
    //   return { text: s, value: s }
    // })
    map[type] = selections
  }

  return map
}

// ----------------------------------------------------- Event Line Control add-on -----------------------------------------------------
/**
 * Gets options for use in the mapbox eventlines control add-on
 */
export function eventLineOptions (state) {
  const categories = ['eventline']
  const matching = state.layerObjs.filter(l => categories.includes(l.properties.category))

  const options = matching.map(l => {
    return { text: l.properties.label || l.id, value: l.id }
  })

  // Sort options alphabetically
  const sorted = options.sort((a, b) => {
    if (a.text < b.text) return -1
    if (a.text > b.text) return 1
    return 0
  })

  return sorted
}

/**
 * Gets initial options for use in the mapbox eventlines control add-on
 */
export function initialEventLines (state) {
  const categories = ['eventline']
  const matching = state.layerObjs.filter(l => categories.includes(l.properties.category) && l.properties.isInitial)
  return matching.map(l => l.id)
}

// ----------------------------------------------------- Risk Toggle add-on -----------------------------------------------------
export function riskOptions (state) {
  const categories = ['risk']
  const matching = state.layerObjs.filter(l => categories.includes(l.properties.category))

  const options = matching.map(l => {
    return { text: l.properties.label || l.id, value: l.id }
  })

  // Sort options alphabetically
  const sorted = options.sort((a, b) => {
    if (a.text < b.text) return -1
    if (a.text > b.text) return 1
    return 0
  })

  return sorted
}

/**
 * Gets initial options for use in the mapbox event control add-on
 */
export function initialRisks (state) {
  const categories = ['risk']
  const matching = state.layerObjs.filter(l => categories.includes(l.properties.category) && l.properties.isInitial)
  return matching.map(l => l.id)
}

// ----------------------------------------------------- Risk Menu Control add-on -----------------------------------------------------
export function riskMenuListedOptions (state) {
  const categories = ['risk']
  const matching = state.layerObjs.filter(l => categories.includes(l.properties.category) && l.properties.categoryType && l.properties.selection)
  const map = {}

  // Map category types as Object property keys
  matching.forEach(l => {
    map[l.properties.categoryType] = []
  })

  // Map unique selections to the mapped category types
  for (const type in map) {
    const objs = matching.filter(l => l.properties.categoryType === type)

    const selections = objs.map(l => {
      return {
        text: l.properties.label,
        value: l.id
      }
    })

    map[type] = selections
  }

  return map
}

export function riskMenuSepOptions (state) {
  const categories = ['risk']
  const matching = state.layerObjs.filter(l => categories.includes(l.properties.category) && l.properties.categoryType && !l.properties.selection)
  const map = {}

  // Map category types as Object property keys
  matching.forEach(l => {
    map[l.properties.categoryType] = []
  })

  // Map unique selections to the mapped category types
  for (const type in map) {
    const objs = matching.filter(l => l.properties.categoryType === type)

    const selections = objs.map(l => {
      return {
        text: l.properties.label,
        value: l.id
      }
    })

    map[type] = selections
  }

  return map
}

export function initialRiskMenuListed (state) {
  const categories = ['risk']
  const matching = state.layerObjs.filter(l => categories.includes(l.properties.category) && l.properties.categoryType && l.properties.selection && l.properties.isInitial)
  const map = {}

  // Map category types as Object property keys
  matching.forEach(l => {
    map[l.properties.categoryType] = []
  })

  // Map unique selections to the mapped category types
  for (const type in map) {
    const objs = matching.filter(l => l.properties.categoryType === type)

    const selections = objs.map(l => {
      return {
        text: l.properties.label,
        value: l.id
      }
    })

    map[type] = selections
  }

  return map
}

export function initialRiskMenuSep (state) {
  const categories = ['risk']
  const matching = state.layerObjs.filter(l => categories.includes(l.properties.category) && l.properties.categoryType && !l.properties.selection && l.properties.isInitial)
  const map = {}

  // Map category types as Object property keys
  matching.forEach(l => {
    map[l.properties.categoryType] = []
  })

  // Map unique selections to the mapped category types
  for (const type in map) {
    const objs = matching.filter(l => l.properties.categoryType === type)

    const selections = objs.map(l => {
      return {
        text: l.properties.label,
        value: l.id
      }
    })

    map[type] = selections
  }

  return map
}

// ----------------------------------------------------- Risk Control add-on -----------------------------------------------------
export function riskPofCofOptions (state) {
  const categories = ['risk-pof-cof']
  const matching = state.layerObjs.filter(l => categories.includes(l.properties.category))

  return matching.map(l => {
    return { text: l.id, value: l.id }
  })
}
