export default {
  // Is this app active?
  enabled: true,

  // App namespace
  ns: 'survey-planning',

  // App makes use of routing
  usesRouting: true,

  // The name of this app
  name: 'Inspection Planning',

  // Alternative label
  label: 'Inspection Planning',

  // Nav item secondary text
  subtext: 'Prediction based planning',

  // Priority of order to show in nav drawer, lower means shown higher in the list
  priority: 5,

  // Icon to use to identify this app
  icon: 'schedule',

  // Enabled app features
  features: {
    planning: true, // Planning block
    revisions: true, // Planning revisions
    optimisation: true // IRM Optimisation
  }
}
